import React, { useEffect } from 'react';
import SEO from '../components/seo';
import '../scss/homepage.scss';
import * as Icons from 'react-feather';
import { Link } from 'gatsby';
import { aboutPath, mopedsPath } from '../gatsby/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import Hour from '../models/Hour';
import Moped from '../models/Moped';
import MopedCard from '../components/MopedCard';
import { Helmet } from 'react-helmet';

export interface IndexPageProps {
  pageContext: {
    about?: string,
    aboutShort?: string,
    hours: Hour[],
    location: string,
    mopeds?: Moped[]
  }
}

const IndexPage: React.FC<IndexPageProps> = ({ pageContext }) => {
  const { about, aboutShort, hours, location, mopeds } = pageContext;

  return (
    <div className="landing-page">
      <SEO />

      <div className="center max-screen-height landing-title">
        <Helmet>
          <meta name="description" content={about} />
          <title>Long Run Mopeds - High Quality New and Pre-Owned Mopeds</title>
        </Helmet>
        <h1>Long Run Mopeds</h1>
        <h2>Quality At Affordable Prices</h2>
        <div className="btn-container">
          <Link to={mopedsPath} style={{
            textDecoration: 'none',
          }}>
            <div className="primary-btn">
              <span style={{ width: '20px' }}>
              </span>Shop Mopeds<span style={{ width: '8px' }}></span>
              <span className="material-icons">arrow_forward</span>
            </div>
          </Link>
        </div>
        <div className="arrow-down">
          <span className="material-icons">expand_more</span>
        </div>
      </div>

      {
        about && (
          <div className="center about">
            <h2>About Us</h2>
            <hr />
            <p>
              {about}
            </p>
            <Link to={aboutPath} className="about-link">
              Learn More
              <span className="icon material-icons">east</span>
            </Link>
          </div>
        )
      }

      <div className="facts">
        <div className="fact">
          <div className="icon">
            <Icons.Zap size={50} />
          </div>
          <div className="spacing"></div>
          <h2>Fast Service</h2>
          <p>Fast, friendly and efficient service from an expert industry veteran.</p>
        </div>
        <div className="fact">
          <div className="icon">
            <Icons.Star size={50} />
          </div>
          <div className="spacing"></div>
          <h2>Expertise</h2>
          <p>We've been around since 1979, our expertise is unmatched.</p>
        </div>
        <div className="fact">
          <div className="icon">
            <Icons.Tool size={50} />
          </div>
          <div className="spacing"></div>
          <h2>Customer Support</h2>
          <p>We're available via phone, email or at our Vinton location.</p>
        </div>
      </div>

      <h1 className="title reviews-title">What Our Clients Say</h1>
      <hr className="thick" style={{
        marginTop: '24px'
      }} />
      <div className="center reviews">
        <div className="review-card">
          <span className="fa-icon"><FontAwesomeIcon icon={faQuoteLeft} /></span>
          <p>"I can't believe how easy and fun it is to ride my new scooter. I get 100 MPG.”</p>
          <small>Hannah Walker</small>
        </div>
        <div className="review-card">
          <span className="fa-icon"><FontAwesomeIcon icon={faQuoteLeft} /></span>
          <p>"Tom has been a great help, we managed to find the perfect bike for our son.”</p>
          <small>James Weister</small>
        </div>
        <div className="review-card">
          <span className="fa-icon"><FontAwesomeIcon icon={faQuoteLeft} /></span>
          <p>"It's been a refreshing experience, Tommy made it simple and easy.”</p>
          <small>Mike Cartwright</small>
        </div>
      </div>

      {
        hours && location && (
          <div>
            <h3 className="small-header location-header">Centrally Located</h3>
            <hr className="thick" />
            <div className="row centrally-located">
              <div className="time-and-location">
                <h2>Opening Times</h2>
                <div className="times">
                  {
                    hours.map(hour => (
                      <div key={hour.days} className="row time">
                        <span className="day">{hour.days}</span>{hour.time}
                      </div>
                    ))
                  }
                </div>
                <h2>Where to find us</h2>
                <div>
                  {location}
                </div>
              </div>
              <div className="map">
                <iframe title="Location" scrolling="none" frameBorder="none" src="https://maps.google.com/maps?q=Long%20Run%20Mopeds%20%2C219%20Virginia%20Ave%2C%20Vinton&t=&z=17&ie=UTF8&iwloc=&output=embed" />
              </div>
            </div>
          </div>
        )
      }

      {
        mopeds && mopeds.length && (
          <div className="discover-mopeds">
            <br />
            <h3 className="small-header location-header discover-mopeds">Discover Our Mopeds</h3>
            <hr className="thick" />
            <div className="center about" style={{ paddingTop: 0, paddingBottom: 0, marginBottom: '-25px' }}>
              <p>
                {aboutShort}
              </p>
            </div>
            <div className="moped-cards discover-mopeds">
              {
                mopeds.map(moped => (
                  <MopedCard key={moped.id} moped={moped} />
                ))
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', fontSize: '18px', marginTop: '24px' }}>
              <Link to={mopedsPath} className="about-link">
                See All
                <span className="icon material-icons">east</span>
              </Link>
            </div>
            <br />
            <br />
          </div>
        )
      }

      <br />
      <br />
    </div>
  )
}

export default IndexPage;